import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkRouter } from 'react-router-dom';

export const TopbarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0a51a0;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  overflow-y: auto;
`;

export const CloseIcon = styled(FaTimes)`
  color: ${({ color }) => (color !== undefined ? color : '#fff')};
`;

export const Icon = styled.div`
  position: absolute;
  transform: translate(-150%, 60%);
  font-size: 1.8rem;
  top: 0;
  right: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
`;

export const TopbarWrapper = styled.div`
  color: white;
`;

export const TopbarMenu = styled.nav`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const TopbarLink = styled(LinkRouter)`
  border-radius: 50px;
  white-space: nowrap;
  padding: 16px 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  outline: none;
  border: none;

  &:hover {
    color: #000;
    transition: 0.2s ease-in-out;
    font-weight: 800;
    text-decoration: none;
  }

  &:focus,
  &:active {
    text-decoration: none;
    color: ${({ href }) => (href ? '#0a51a0' : '#fbf7f0')};
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-15%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const TopbarLinkExpanded = styled(TopbarLink)`
  background-color: hsla(142, 17%, 87%, 0.5);
  border-radius: unset;
  white-space: break-spaces;
  text-align: center;
  padding: 1rem 1rem;
  font-size: 1.3em;
  width: 70%;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ExpandedContainer = styled.div`
  animation: ${slideDown} 0.4s linear both;
  max-width: 80%;
  margin: auto;
`;
