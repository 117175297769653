import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: relative;
  background: #0c0c0c;
  overflow-x: hidden;
  color: #fbf7f0;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 0.8rem;

  @media screen and (max-width: 1200px) {
    font-size: 2.3vmin;
  }
`;
