import React, { useState, useEffect } from 'react';
import {
  TopbarContainer,
  Icon,
  CloseIcon,
  TopbarWrapper,
  TopbarLink,
  TopbarLinkExpanded,
  TopbarMenu,
  ExpandedContainer,
} from './TopbarElements';
import { programs } from '../Navbar/dropdownValues';

const Topbar = ({ isOpen, toggle }) => {
  const touchMoveHandle = (e) => e.preventDefault();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('topbar-open');
      document.body.addEventListener('touchmove', touchMoveHandle);
    } else {
      document.body.classList.remove('topbar-open');
      document.body.removeEventListener('touchmove', touchMoveHandle);
    }
    return function cleanup() {
      document.body.classList.remove('topbar-open');
      document.body.removeEventListener('touchmove', touchMoveHandle);
    };
  }, [isOpen]);

  const [openPrograms, setOpenPrograms] = useState(false);

  return (
    <TopbarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <TopbarWrapper>
        <TopbarMenu>
          <TopbarLink onClick={toggle} to="about">
            o nama
          </TopbarLink>
          <TopbarLink onClick={toggle} to="iso-standard">
            iso standard
          </TopbarLink>
          <TopbarLink onClick={() => setOpenPrograms((prev) => !prev)} to="#">
            naši programi
          </TopbarLink>
          {openPrograms && (
            <ExpandedContainer>
              {programs.map((program) => (
                <TopbarLinkExpanded
                  key={program.name}
                  onClick={() => {
                    toggle();
                    setOpenPrograms(false);
                  }}
                  to={program.to}
                >
                  {program.name}
                </TopbarLinkExpanded>
              ))}
            </ExpandedContainer>
          )}
          <TopbarLink onClick={toggle} to="kako-naruciti">
            kako naručiti
          </TopbarLink>
          <TopbarLink onClick={toggle} to="kontakt">
            kontakt
          </TopbarLink>
        </TopbarMenu>
      </TopbarWrapper>
    </TopbarContainer>
  );
};

export default Topbar;
