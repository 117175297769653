import React from 'react';
import Loader from 'rsuite/lib/Loader';

const FallBackLoaderWebsite = () => {
  const loaderStyle = {
    minHeight: '100vh',
    minWidth: '100vw',
    overflow: 'hidden',
  };

  return (
    <div style={loaderStyle}>
      <Loader vertical size="lg" backdrop center content="Učitavanje..." />
    </div>
  );
};

export default FallBackLoaderWebsite;
