import React, { useState, useEffect, useRef } from 'react';
import TopBar from '../Topbar';
import { FaBars, FaCaretDown } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import LogoDark from '../../assets/images/logo.svg';
import LogoLight from '../../assets/images/logoLight.svg';
import Dropdown from './Dropdown';
import './style.css';
import {
  about as aboutDropdown,
  programs as programDropdown,
} from './dropdownValues';
import {
  Navbar,
  NavigationContainer,
  NavLink,
  MobileIcon,
  NavItem,
  NavItemWrapper,
  LogoImage,
} from './NavbarElements';

const NavigationBar = ({ ...rest }) => {
  const [scrollNav, setScrollNav] = useState(0);
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const navRef = useRef();
  navRef.current = scrollNav;

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const toggleFooter = () => {
    if (window.location.pathname === '/') scroll.scrollToBottom();
  };

  useEffect(() => {
    const changeNavStyle = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY >= 200) {
        setScrollNav(1);
      } else {
        setScrollNav(0);
      }
    };
    window.addEventListener('scroll', changeNavStyle, { passive: true });
    return () => window.removeEventListener('scroll', changeNavStyle);
  }, []);

  const logoStyle = { maxWidth: '200px' };

  return (
    <Navbar topbarOpen={isOpen} scrollnav={navRef.current} {...rest}>
      <TopBar isOpen={isOpen} toggle={toggleNavbar} />
      <NavLink
        scrollnav={navRef.current}
        to="/"
        logo="true"
        onClick={toggleHome}
      >
        {!isOpen &&
          (!navRef.current ? (
            <LogoImage
              src={LogoLight}
              className="bosna-vet-logo"
              style={logoStyle}
              alt="Bosna Vet"
            />
          ) : (
            <LogoImage
              src={LogoDark}
              className="bosna-vet-logo"
              style={logoStyle}
              alt="Bosna Vet"
            />
          ))}
      </NavLink>
      <MobileIcon scrollnav={navRef.current} onClick={toggleNavbar}>
        <FaBars />
      </MobileIcon>
      <NavigationContainer>
        <NavItemWrapper className="nav-items">
          <NavLink
            className="nav-item-link"
            activeClassName="nav-item-active"
            scrollnav={navRef.current}
            to="/about"
          >
            <NavItem>
              o nama
              <FaCaretDown />
            </NavItem>
          </NavLink>
          <Dropdown scrollnav={navRef.current} itemList={[aboutDropdown]} />
        </NavItemWrapper>
        <NavItemWrapper className="nav-items">
          <NavLink className="nav-item-link" scrollnav={navRef.current} to="#">
            <NavItem>
              naši programi
              <FaCaretDown />
            </NavItem>
          </NavLink>
          <Dropdown scrollnav={navRef.current} itemList={programDropdown} />
        </NavItemWrapper>
        <NavItemWrapper className="nav-items">
          <NavLink
            className="nav-item-link"
            activeClassName="nav-item-active"
            scrollnav={navRef.current}
            to="/kako-naruciti"
          >
            <NavItem>kako naručiti</NavItem>
          </NavLink>
        </NavItemWrapper>
        <NavItemWrapper className="nav-items">
          <NavLink
            className="nav-item-link"
            scrollnav={navRef.current}
            to={() => (window.location.pathname !== '/' ? '/kontakt' : '#')}
            onClick={toggleFooter}
          >
            <NavItem>kontakt</NavItem>
          </NavLink>
        </NavItemWrapper>
      </NavigationContainer>
    </Navbar>
  );
};

export default NavigationBar;
