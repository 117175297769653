import React from 'react';

import { DropdownList, DropDownItem } from './NavbarElements';
import './style.css';

const Dropdown = ({ itemList, scrollnav, ...props }) => {
  return (
    <DropdownList className="nav-item-drop" scrollnav={scrollnav}>
      {itemList.map((item) => (
        <DropDownItem
          activeClassName="nav-item-active"
          key={item.name}
          to={item.to}
          style={{ fontWeight: item.bold ? 'bold' : 'normal' }}
        >
          {item.name}
        </DropDownItem>
      ))}
    </DropdownList>
  );
};

export default Dropdown;
