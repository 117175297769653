export const about = {
  name: 'ISO Standard',
  to: '/iso-standard',
};

export const programs = [
  {
    name: 'Veterina',
    to: '/veterina',
    bold: true,
  },
  {
    name: 'DSS',
    to: '/dss',
  },
  {
    name: 'DDD',
    to: '/ddd',
  },
  { name: 'Veterinarski lijekovi', to: '/veterinarski-lijekovi' },
  {
    name: 'Veterinarski instrumenti i oprema',
    to: '/veterinarski-instrumenti-i-oprema',
  },
  { name: 'Apoteka', to: '/apoteka' },
  {
    name: 'DIJAGNOSTIKA I LABORATORIJ',
    to: '/dijagnostika-i-laboratorij',
    bold: true,
  },
  { name: 'Zdravlje životinja', to: '/zdravlje-zivotinja' },
  { name: 'Javno zdravstvo', to: '/javno-zdravstvo' },
  { name: 'Sigurnost ishrane', to: '/sigurnost-ishrane' },
];
