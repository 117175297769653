import React, { lazy } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import NavigationBar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import withSuspense from './helpers/withSuspense';
import 'rsuite/dist/styles/rsuite-dark.css';

function App() {
  const Home = withSuspense(lazy(() => import('./pages/Home')));
  const About = withSuspense(lazy(() => import('./pages/About')));
  const ISOStandard = withSuspense(
    lazy(() => import('./pages/About/ISOStandard'))
  );
  const VeterinaryPage = withSuspense(lazy(() => import('./pages/Veterinary')));
  const Dss = withSuspense(lazy(() => import('./pages/Dss')));
  const Ddd = withSuspense(lazy(() => import('./pages/Ddd')));
  const VetMedicine = withSuspense(lazy(() => import('./pages/Medicine')));
  const VetEquipment = withSuspense(lazy(() => import('./pages/Equipment')));
  const Pharmacy = withSuspense(lazy(() => import('./pages/Pharmacy')));
  const DiagnosticsLaboratory = withSuspense(
    lazy(() => import('./pages/DiagnosticsLaboratory'))
  );
  const AnimalHealth = withSuspense(lazy(() => import('./pages/AnimalHealth')));
  const PublicHealth = withSuspense(lazy(() => import('./pages/PublicHealth')));
  const FoodSafeness = withSuspense(lazy(() => import('./pages/FoodSafeness')));
  const OtherSuppliers = withSuspense(
    lazy(() => import('./pages/OtherSuppliers'))
  );
  const Order = withSuspense(lazy(() => import('./pages/Order')));
  const Contact = withSuspense(lazy(() => import('./pages/Contact')));
  const NotFound = withSuspense(lazy(() => import('./pages/NotFound')));

  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <ScrollToTop />
        <Switch>
          <Route path="/" render={(props) => <Home {...props} />} exact />
          <Route
            path="/(about|o-nama)"
            render={(props) => <About {...props} />}
            exact
          />
          <Route
            path="/iso-standard"
            render={(props) => <ISOStandard {...props} />}
            exact
          />
          <Route
            path="/veterina"
            render={(props) => <VeterinaryPage {...props} />}
            exact
          />
          <Route path="/dss" render={(props) => <Dss {...props} />} exact />
          <Route path="/ddd" render={(props) => <Ddd {...props} />} exact />
          <Route
            path="/veterinarski-lijekovi"
            render={(props) => <VetMedicine {...props} />}
            exact
          />
          <Route
            path="/veterinarski-instrumenti-i-oprema"
            render={(props) => <VetEquipment {...props} />}
            exact
          />
          <Route
            path="/ostali-proizvodjaci"
            render={(props) => <OtherSuppliers {...props} />}
            exact
          />
          <Route
            path="/apoteka"
            render={(props) => <Pharmacy {...props} />}
            exact
          />
          <Route
            path="/dijagnostika-i-laboratorij"
            render={(props) => <DiagnosticsLaboratory {...props} />}
            exact
          />
          <Route
            path="/zdravlje-zivotinja"
            render={(props) => <AnimalHealth {...props} />}
            exact
          />
          <Route
            path="/javno-zdravstvo"
            render={(props) => <PublicHealth {...props} />}
            exact
          />
          <Route
            path="/sigurnost-ishrane"
            render={(props) => <FoodSafeness {...props} />}
            exact
          />
          <Route
            path="/kako-naruciti"
            render={(props) => <Order {...props} />}
            exact
          />
          <Route
            path="/kontakt"
            render={(props) => <Contact {...props} />}
            exact
          />
          <Route
            path="/build"
            render={() => <Redirect to={{ pathname: '/' }} />}
            exact
          />
          <Redirect from="/build/:path" to="/:path" />
          <Route render={(props) => <NotFound {...props} />} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
