import { Footer } from './style';

const footerStyle = { color: '#fbf7f0', marginLeft: '.2rem' };

const PageFooter = () => {
  return (
    <Footer>
      Copyright {new Date().getFullYear()} © BosnaVet ---{'  '}
      <a
        style={footerStyle}
        href="https://webmail.your-server.de/login.php"
        target="_blank"
        rel="noopener noreferrer"
      >
        WEBMAIL
      </a>
    </Footer>
  );
};

export default PageFooter;
