import styled, { css, keyframes } from 'styled-components';
import { NavLink as LinkRouter } from 'react-router-dom';

export const Navbar = styled.div`
  margin: 0;
  padding: 0;
  will-change: transform;
  background-image: linear-gradient(to bottom, transparent 50%, #fbf7f0 50%);
  background-position: ${({ scrollnav }) => (scrollnav ? '0 -100%' : 'none')};
  background-size: 100% 200%;
  display: flex;
  position: fixed;
  width: 100vw;
  height: ${({ topbarOpen }) => (topbarOpen ? '100vh' : '80px')};
  top: 0;
  z-index: 10;
  transition: 0.4s all ease;

  ${({ scrollnav }) =>
    scrollnav &&
    css`
      filter: drop-shadow(0 0.1rem 0.4rem #555555);
    `}

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavigationContainer = styled.ul`
  display: flex;
  width: 100vw;
  padding: 5px;
  margin-left: 20%;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  padding: 0 24px;

  @media screen and (max-width: 900px) {
    margin-left: 10%;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
`;

export const LogoImage = styled.img`
  animation: ${slideDown} 0.3s linear forwards;
`;

export const NavLink = styled(LinkRouter)`
  color: ${({ scrollnav }) => (scrollnav ? '#555555' : '#fbf7f0')};
  height: 100%;
  display: flex;
  justify-self: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  flex: 1;
  ${({ logo }) =>
    logo &&
    css`
      margin-left: 100px;
      margin-right: 100px;
      color: #d9e4dd;
      justify-content: center;
      font-size: 1.7rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
      min-width: 200px;
      flex: 2;
      @media screen and (max-width: 375px) {
        margin-left: 20px;
      }
    `}
  transition: 0.2s ease-in-out;
  &:hover {
    transition: 0.2s ease-in-out;
    color: #0a51a0;
    text-decoration: none;
  }
  &:focus,
  &:active {
    text-decoration: none;
    color: #d9e4dd;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1200px) {
    display: block;
    color: ${({ scrollnav }) => (scrollnav ? '#555555' : '#fbf7f0')};
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-150%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavItem = styled.p`
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: center;
  margin: auto;
  height: 100%;
  cursor: pointer;
  pointer-events: none;
  letter-spacing: 0.07rem;
`;

export const NavItemWrapper = styled.li`
  display: inline-block;
  position: relative;
  height: 100%;
`;

export const DropDownItem = styled(LinkRouter)`
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin: 5px;
  color: #555555;
  font-size: 18px;

  transition: 0.2s ease-in-out;
  &:hover {
    transition: 0.2s ease-in-out;
    color: #0a51a0;
    transform: scale(1.1);
    text-decoration: none;
  }
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
  }
`;

export const DropdownList = styled.ul`
  width: 200px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-20px) translateX(-51%);
  background: #fbf7f0;
  position: absolute;
  left: 51%;
  padding: 0;
  top: 100%;
  min-width: 100%;
  overflow: hidden;
  list-style: none;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.2s ease-in-out, opacity 0.1s ease-out;
`;
