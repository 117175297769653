import React, { Suspense } from 'react';
import FallBackLoaderWebsite from '../components/FallBackLoaderWebsite';

const withSuspense = (WrappedComponent) => {
  return (props) => (
    <Suspense fallback={<FallBackLoaderWebsite />}>
      <WrappedComponent {...props} />
    </Suspense>
  );
};

export default withSuspense;
